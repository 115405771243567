<script lang="ts">

import carousel from 'vue-owl-carousel'
export default {
  components: { carousel },
  render() {
    return this.$scopedSlots.default(this)
  }
}

</script>
