<template>
  <carousel
    class="media-content"
    :margin="config.margin"
    :loop="config.loop"
    :items="config.items"
    :autoHeight="config.autoHeight"
    :autoWidth="config.autoWidth"
    :dots="config.dots"
    :responsiveClass="config.responsiveClass"
    :autoplay="config.autoplay"
    :nav="config.nav"
    :responsive="config.repsponsive">

    <div class="item" v-for="image in images">
      <img :src="image.src" :alt="image.alt" />
    </div>
  </carousel>
</template>

<script lang="ts">

import carousel from 'vue-owl-carousel'

export default {
  components: { carousel },
  props: {
    images: {
      type: Array<{src: string, alt: string}>,
      default: []
    },
    config: {
      type: Object,
      default: {
        margin: 20,
        loop: true,
        items: 1.5,
        autoHeight: false,
        autoWidth: true,
        dots: false,
        responsiveClass: true,
        autoplay: true,
        nav: false,
        repsponsive: {
          768: {
            items: 4,
            autoHeight: true,
            autoWeight: true
          }
        }
      }
    }
  }
}
</script>
