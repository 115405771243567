<template>
  <div class="wrap-product-slider">
    <h3 class="text-center mb-5">{{ header}}</h3>
    <carousel :loop="config.loop"
              :repsponsiveClass="config.repsponsiveClass"
              :margin="config.margin"
              :items="config.items"
              :dots="config.dots"
              :autoplay="config.autoplay"
              :nav="config.nav"
              :center="config.center"
              :responsive="config.responsive">

      <div class="product" v-for="product in products">
        <div class="wrap-image">
          <img :src="product.image.src" :alt="product.image.alt" />
        </div>
        <div class="all-info p-3 md:p-5">
          <p class="name mb-1 md:mb-3">{{ product.name }}</p>
          <div class="wrap-detail flex gap-3 md:gap-7 mb-2 md:mb-4">
            <div class="price">
              <p>Starting Price</p>
              <span>{{ product.startingPrice }}</span>
            </div>
            <div class="power">
              <p>Power</p>
              <span>{{ product.power }}</span>
            </div>
            <div class="tank">
              <p>Tank</p>
              <span>{{ product.tank }}</span>
            </div>
          </div>
          <div class="action flex items-center justify-center">
            <a :href="product.url" class="block p-0">View</a>
          </div>
        </div>
      </div>
    </carousel>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import carousel from 'vue-owl-carousel'

type Product = {
  name: string;
  url: string;
  startingPrice: string;
  power: string;
  tank: string;
  image: {
    src: string;
    alt: string;
  }
}
type CarouselConfig = {
  loop: boolean;
  repsponsiveClass: string;
  items: number;
  margin: number;
  dots: boolean;
  autoplay: boolean;
  nav: boolean;
  center: boolean;
  responsive: Object;
}

export default {
  components: { carousel },
  props: {
    products: Array as PropType<Product[]>,
    header: String,
    config: {
      type: Object as PropType<CarouselConfig>,
      default: {
        loop: true,
        items: 1.2,
        dots: true,
        autoplay: true,
        nav: false,
        center: true,
        responsive: {0:{items:1.2,margin:10},768:{items:3.2}}
      }
    }
  }
}
</script>
