<template>
  <div class="wrap-banner">
    <carousel class="banner-slider"
              :loop="config.loop"
              :items="config.items"
              :dots="config.dots"
              :autoplay="config.autoplay"
              :nav="config.nav">
      <a class="slide" v-for="banner in banners" href="#">
        <img :src="banner.image.src" :alt="banner.image.alt" />
      </a>
    </carousel>
  </div>
</template>
<script lang="ts">
import { PropType } from 'vue'
import carousel from 'vue-owl-carousel'

type Banner = {
  image: BannerImage;
  text: string; // rich text
  button: BannerAction;
}

type BannerImage = {
  src: string;
  alt: string;
}

type BannerAction = {
  text: string;
  url: string;
  target: string; // _blank or _self, default is _self
}

type CarouselConfig = {
  loop: boolean;
  items: number;
  dots: boolean;
  autoplay: boolean;
  nav: boolean;
}

export default {
  components: {carousel},
  props: {
    banners: {
      type: Array as PropType<Banner[]>,
      default: []
    },
    config: {
      type: Object as PropType<CarouselConfig>,
      default: {
        loop: true,
        items: 1,
        dots: true,
        autoplay: true,
        nav: false
      }
    }
  },
}
</script>
